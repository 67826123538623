import { useEffect, useRef, useState } from 'react';
import './App.css';
import CountDownPhone from './page/CountDownPhone';
import SettingPage from './page/SettingPage';
import CountDownClock from './utils/CountDownClock';
import { isSynced, syncTime, getTime, getTimeOffset } from './utils/SyncTime';

function App() {
    let clockRef = useRef();
    let [timeText, setTimeText] = useState("00:00:00");
    let [status, setStatus] = useState("home");
    let [synced, setSynced] = useState(false);
    let syncedRef = useRef(false);
    let timeIdRef = useRef();
    /**
     * @type {CountDownClock}
     */
    let clock = clockRef.current;

    useEffect(() => {
        window.onpopstate = onPopstate;
        init();
        return () => {
            clearInterval(timeIdRef.current);
        }
    }, []);

    function onPopstate() {
        if (window.location.pathname == "/") {
            setStatus("home");
            clearInterval(timeIdRef.current);
            clockRef.current = null;
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
    }

    async function init() {
        await syncTime();
        await syncTime();
        syncedRef.current = true;
        setSynced(true);
    }

    function setClock(clock) {
        window.history.pushState(null, null, "/timer");
        clockRef.current = clock;
        timeIdRef.current = setInterval(update, 51);
        update();
    }

    function update() {
        if (clockRef.current) {
            setTimeText(clockRef.current.getTimeText());
        }
    }

    return <div>
        <div className={status != "home" ? " hidden" : ""}>
            <SettingPage clockRef={clockRef} setStatus={setStatus} synced={synced} setClock={setClock}></SettingPage>
        </div>
        <div className={status != "phone" ? " hidden" : ""}>
            <CountDownPhone clock={clock} timeText={timeText} status={status}></CountDownPhone>
        </div>
    </div>;
}

export default App;
