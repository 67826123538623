import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Div100vh from 'react-div-100vh'
import bot1 from "../image/bot-01.svg";
import bot2 from "../image/bot-02.svg";
import bot3 from "../image/bot-03.svg";
import ClockSettingModal from "./ClockSettingModal";

export default function CountDownPhone({ clock, timeText, status }) {
    let [fontSize, setFontSize] = useState(16);
    let [showSetting, setShowSetting] = useState(false);
    let fontSizeRef = useRef(fontSize);
    let clickTimeIdRef = useRef(0);
    let clickCountRef = useRef(0);
    let title1Ref = useRef();
    let title2Ref = useRef();
    fontSizeRef.current = fontSize;

    function resize() {
        let title1 = title1Ref.current;
        let title2 = title2Ref.current;
        if (title1 && title2) {
            let title1Width = title1.offsetWidth;
            let title2Width = title2.offsetWidth;
            let width = Math.min(title1Width, title2Width);
            let windowWidth = (window.innerWidth > 600 ? 600 : window.innerWidth) - 32;
            if (width <= 0) {
                return;
            }
            fontSize = windowWidth / width * fontSizeRef.current - 4;
            if (fontSize < 16 || fontSize >= 300 || !fontSize) {
                fontSize = 16;
                setTimeout(() => {
                    resize();
                }, 20);
            }
        }
        setFontSize(fontSize);
    }

    useEffect(() => {
        resize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    function onResize() {
        resize();
        setTimeout(() => {
            resize();
        }, 1000)
    }

    useLayoutEffect(() => {
        if (status == "phone") {
            if (document.body.requestFullscreen) {
                document.body.requestFullscreen().finally(() => {
                    resize();
                });
            }
            setTimeout(() => {
                resize();
                window.scrollTo(0, 1)
            }, 20);
        }
    }, [status]);

    if (!clock) {
        return <div></div>
    }
    let bgColor = "#fff";
    let image = bot1;
    // 0: not started, 1: started, 2: paused, 3: stopped
    if (clock.status == 0) {
        bgColor = "#0AD408";
    } else if (clock.status == 1 || clock.status == 2) {
        bgColor = "#D84102";
        image = bot2;
    } else if (clock.status == 3) {
        bgColor = "#E40508";
        image = bot3;
    }

    function click() {
        if (showSetting) {
            return;
        }
        clearTimeout(clickTimeIdRef.current);
        clickCountRef.current++;
        if (clickCountRef.current >= 3) {
            clickCountRef.current = 0;
            setShowSetting(true);
            return;
        }
        clickTimeIdRef.current = setTimeout(() => {
            if (clock.type == "owner" && clock.status == 0) {
                clock.start();
            }
            clickCountRef.current = 0;
        }, 350);
    }

    return <Div100vh onTouchEnd={(e) => {
        click();
    }} onClick={(e) => {
        click()
    }} style={{
        backgroundColor: bgColor,
    }}>
        <div className="h-full max-w-[600px] mx-auto p-4 select-none">
            <div className="flex flex-col h-full">
                <div className="text-center text-white" style={{
                    fontSize: fontSize + "px",
                }}>
                    <span ref={title1Ref}>PUNISHMENT</span>
                </div>
                <div className="flex-1 flex relative">
                    <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full h-fit">
                        <img className="pointer-events-none" src={image} alt="" />
                        <div className=" absolute top-[34%] text-white left-1/2 -translate-x-1/2 leading-none time-font" style={{
                            fontSize: (fontSize - 2) + "px",
                        }}>{timeText}</div>
                    </div>
                </div>
                <div className="text-center text-white" style={{
                    fontSize: fontSize + "px",
                }}>
                    <span ref={title2Ref}>EXPLOSION!!!</span>
                </div>
            </div>
        </div>
        <ClockSettingModal show={showSetting} onClose={() => {
            setTimeout(() => {
                setShowSetting(false);
            });
        }} clock={clock}></ClockSettingModal>
    </Div100vh>
}