import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, get, child, update, equalTo, query } from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAESLfKauuzykikAPqbbCk03w4gsO3_m9s",
    authDomain: "lycoris-recoil-count-down.firebaseapp.com",
    databaseURL: "https://lycoris-recoil-count-down-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "lycoris-recoil-count-down",
    storageBucket: "lycoris-recoil-count-down.appspot.com",
    messagingSenderId: "946365945421",
    appId: "1:946365945421:web:8eef0b5d614d0d8adeae04",
    measurementId: "G-KH13G24NB5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

function getDB(id) {
    let db = ref(database, `clocks/${id}/data`);
    return db;
}

function setToken(token) {
    return new Promise((resolve, reject) => {
        auth.onAuthStateChanged(resolve);
        signInWithCustomToken(auth, token);
    });
}

export default {
    setToken,
    getDB
};