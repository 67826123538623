import { getTime } from "./SyncTime";
import RealtimeDB from "./RealtimeDB";
import { onValue, set } from "firebase/database";

export default class CountDownClock {
    constructor() {
        this.id = "";
        this.type = "guest";// owner, guest
        this.startTime = 0;
        this.status = 0;// 0: not started, 1: started, 2: paused, 3: stopped
        this.length = 0;
        this.pauseTime = 0;
        this.db = null;
        this.offsetTime = 0;
    }

    create() {
        if (this.id == "") {
            return;
        }
        this.db = RealtimeDB.getDB(this.id);
        if (this.type == "owner") {
            this.onChnage();
        } else {
            onValue(this.db, (snapshot) => {
                let data = snapshot.val();
                this.startTime = data.startTime;
                this.status = data.status;
                this.length = data.length;
                this.pauseTime = data.pauseTime;
            });
        }
    }

    getTime() {
        if (this.status === 0) {
            return this.length;
        } else if (this.status === 1) {
            let t = this.length - (getTime() - this.startTime) - this.offsetTime;
            if (t < 0) {
                t = 0;
                this.stop();
            }
            if (t > this.length) {
                this.offsetTime = (t - this.length) / 2;
                return this.getTime();
            }
            return t;
        } else if (this.status === 2) {
            return this.pauseTime;
        } else if (this.status === 3) {
            return 0;
        }
    }

    start() {
        if (this.status == 2) {
            this.resume();
            return;
        }

        this.startTime = getTime();
        this.status = 1;
        this.onChnage();
    }

    pause() {
        if (this.status === 1) {
            this.pauseTime = this.length - (getTime() - this.startTime);
            this.status = 2;
            this.onChnage();
        }
    }

    resume() {
        if (this.status === 2) {
            this.startTime = getTime() - (this.length - this.pauseTime);
            this.status = 1;
            this.onChnage();
        }
    }

    stop() {
        this.status = 3;
        this.onChnage();
    }

    reset() {
        this.status = 0;
        this.onChnage();
    }

    getTimeText() {
        let time = this.getTime();
        let min = Math.floor(time / 60000);
        let sec = Math.floor((time - min * 60000) / 1000);
        let ms = Math.floor((time - min * 60000 - sec * 1000) / 10);
        return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}:${ms < 10 ? '0' + ms : ms}`;
    }

    onChnage() {
        if (this.type != "owner") {
            return;
        }

        let data = {
            startTime: this.startTime,
            status: this.status,
            length: this.length,
            pauseTime: this.pauseTime,
        };
        set(this.db, data);
    }
}