import { ChevronDownIcon, ChevronUpIcon, ClipboardIcon, ShareIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react';
import bg1 from "../image/bg1.jpg";
import screenshot1 from "../image/screenshot1.jpg";
import CountDownClock from '../utils/CountDownClock';
import StyleSelector from '../utils/StyleSelector';
import Modal from "../utils/Modal";
import RealtimeDB from '../utils/RealtimeDB';
import { Transition } from '@headlessui/react'
import { QRCodeSVG } from 'qrcode.react';
var firstTime = false;

export default function SettingPage({ clockRef, setStatus, synced, setClock }) {
    let [time, setTime] = useState("60");
    let [id, setID] = useState("");
    let [newID, setNewID] = useState("");
    let [newIdToken, setNewIdToken] = useState("");
    let [newStyle, setNewStyle] = useState("phone");
    let [style, setStyle] = useState("phone");
    let [newIDLoading, setNewIdLoading] = useState(false);
    let [showCreateIDModal, setShowCreateIDModal] = useState(false);
    let [authToken, setAuthToken] = useState("");
    let [showBlock, setShowBlock] = useState("create");
    let [showShare, setShowShare] = useState(false);

    let shareURL = `${window.location.protocol}//${window.location.host}/?shareid=${newID}`;

    useEffect(() => {
        if (firstTime) {
            return
        }
        firstTime = true;

        let url = new URL(window.location.href);
        let shareid = url.searchParams.get("shareid");
        if (shareid && shareid.match(/^[A-Z0-9]{4}$/)) {
            setID(shareid);
            setShowBlock("join");
        }
        if (window.localStorage.getItem("id") && window.localStorage.getItem("token")) {
            let tokenID = window.localStorage.getItem("id");
            let token = window.localStorage.getItem("token");
            let tokenCreatedAt = parseInt(window.localStorage.getItem("created_at"));
            let now = parseInt(Date.now() / 1000);
            if (now - tokenCreatedAt > 60 * 60 * 24 * 7) {
                return;
            }
            setNewID(tokenID);
            setNewIdToken(token);
            getAuthToken(token);
        }
    }, []);

    function request(path, method = "GET", data = undefined) {
        let option = {
            method: method,
        };
        if (data) {
            data = JSON.stringify(data);
            option.headers = {
                "Content-Type": "application/json"
            };
            option.body = data;
        }

        let baseURL = `https://${window.location.host}`;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            baseURL = `https://lycoris-recoil-countdown.pages.dev`;
        }
        return fetch(baseURL + path, option).then((res) => { return res.json(); });
    }

    async function getNewID() {
        setNewIdLoading(true);
        let newData = await request("/api/clock", "POST");
        setNewID(newData.id);
        setNewIdToken(newData.token);
        window.localStorage.setItem("id", newData.id);
        window.localStorage.setItem("token", newData.token);
        window.localStorage.setItem("created_at", Date.now());
        await getAuthToken(newData.token);
        setNewIdLoading(false);
    }

    async function getAuthToken(token) {
        setNewIdLoading(true);
        let authData = {};
        try {
            authData = await request("/api/token", "POST", {
                token: token
            });
        } catch (error) {
        }
        if (!authData.token) {
            setNewID("");
            setNewIdToken("");
            window.localStorage.removeItem("id");
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("created_at");
            setNewIdLoading(false);
        } else {
            await setAuthToken(authData.token);
            RealtimeDB.setToken(authData.token);
        }
        setNewIdLoading(false);
    }

    function create() {
        time = parseInt(time);
        if (!time) {
            return
        }
        if (time < 1) {
            time = 1;
        }
        if (time > 99) {
            time = 99;
        }
        let clock = new CountDownClock();
        clock.length = time * 60 * 1000;
        if (newID != "" && newIdToken != "") {
            clock.id = newID;
            clock.type = "owner";
        } else {
            clock.type = "owner";
        }
        clock.create();
        setClock(clock);
        setStatus(newStyle);
    }

    function join() {
        if (!id || !id.match(/^[A-Z0-9]{4}$/)) {
            return;
        }
        let clock = new CountDownClock();
        clock.id = id;
        clock.type = "guest";
        clock.create();
        setClock(clock);
        setStatus(style);
    }

    function toggleBlock() {
        if (showBlock == "create") {
            setShowBlock("join");
        } else {
            setShowBlock("create");
        }
    }

    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="relative bg-white shadow-xl">
                    <div className="grid grid-cols-1 lg:grid-cols-3 select-none">
                        {/* Contact information */}
                        <div className='h-full relative overflow-hidden'>
                            <img src={bg1} className="absolute top-0 left-0 h-full w-full blur-md object-cover" alt="" />
                            <div className="relative h-full overflow-hidden bg-slate-700/80 pt-10 pb-5 px-6 sm:px-10 xl:p-12">
                                <div className="pointer-events-none absolute inset-0 sm:hidden opacity-20" aria-hidden="true">
                                    <svg
                                        className="absolute inset-0 h-full w-full"
                                        width={343}
                                        height={388}
                                        viewBox="0 0 343 388"
                                        fill="none"
                                        preserveAspectRatio="xMidYMid slice"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                            fill="url(#linear1)"
                                            fillOpacity=".1"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="linear1"
                                                x1="254.553"
                                                y1="107.554"
                                                x2="961.66"
                                                y2="814.66"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stopColor="#fff" />
                                                <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div
                                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden opacity-20"
                                    aria-hidden="true"
                                >
                                    <svg
                                        className="absolute inset-0 h-full w-full"
                                        width={359}
                                        height={339}
                                        viewBox="0 0 359 339"
                                        fill="none"
                                        preserveAspectRatio="xMidYMid slice"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                            fill="url(#linear2)"
                                            fillOpacity=".1"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="linear2"
                                                x1="192.553"
                                                y1="28.553"
                                                x2="899.66"
                                                y2="735.66"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stopColor="#fff" />
                                                <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div
                                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block opacity-20"
                                    aria-hidden="true"
                                >
                                    <svg
                                        className="absolute inset-0 h-full w-full"
                                        width={160}
                                        height={678}
                                        viewBox="0 0 160 678"
                                        fill="none"
                                        preserveAspectRatio="xMidYMid slice"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                            fill="url(#linear3)"
                                            fillOpacity=".5"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="linear3"
                                                x1="192.553"
                                                y1="325.553"
                                                x2="899.66"
                                                y2="1032.66"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stopColor="#fff" />
                                                <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div className='flex flex-col justify-between h-full'>
                                    <div>
                                        <h1 className="text-2xl font-bold text-white">Lycoris Recoil爆炸倒數計時器</h1>
                                        <h2 className="mt-6 max-w-3xl text-base text-slate-50">
                                            Lycoris Recoil 莉可麗絲第12集中的爆炸倒數計時器，可同步多台裝置同時倒數計時。
                                        </h2>
                                        <img src={screenshot1} className="w-full rounded mt-6 pointer-events-none" alt="" />
                                    </div>
                                    <div className=' text-center'>
                                        <span className='text-white mt-4 underline underline-offset-2 cursor-pointer'>
                                            Copyright © 2022
                                            <a href="https://dannyball710.net/" target={"_blank"}> dannyball710.net</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                            <button
                                onClick={() => {
                                    toggleBlock();
                                }}
                                className="relative mt-8 block w-full outline-none">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span className="bg-white pr-3 text-lg font-medium text-gray-900">建立倒數計時器</span>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-full border border-gray-300 bg-white px-1.5 py-1.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        {
                                            showBlock == "create" ?
                                                <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                :
                                                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        }
                                    </button>
                                </div>
                            </button>
                            <Transition
                                show={showBlock == "create"}
                                enter="transition-all duration-500 overflow-hidden"
                                enterFrom="max-h-0"
                                enterTo="max-h-[300vh]"
                                leave="transition-all duration-500 overflow-hidden"
                                leaveFrom="max-h-[300vh]"
                                leaveTo="max-h-0"
                                as='div'
                            >
                                <div className={"mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"}>
                                    <div>
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                                            時間(分鐘)
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={time}
                                                onChange={(e) => setTime(e.target.value)}
                                                type="number"
                                                autoComplete="off"
                                                className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                                            />
                                        </div>
                                    </div>
                                    <div className=' relative'>
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                                            計時器ID
                                        </label>
                                        <div className="mt-1 ">
                                            <div className='relative'>
                                                <input
                                                    disabled={true}
                                                    value={newID}
                                                    onChange={(e) => setNewID(e.target.value)}
                                                    type="text"
                                                    autoComplete="off"
                                                    className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                                                />
                                                <button onClick={() => {
                                                    setShowShare(true);
                                                }} className='absolute top-1/2 -translate-y-1/2 right-2 inline-flex items-center justify-center rounded-md border border-transparent bg-slate-600 p-2 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 w-auto'>
                                                    <ShareIcon className='w-5 h-5 text-white' />
                                                </button>
                                            </div>
                                            <div className=' text-right'>
                                                <button className=' underline underline-offset-2 text-sm cursor-pointer' onClick={() => {
                                                    setNewID("");
                                                    setNewIdToken("");
                                                    window.localStorage.removeItem("id");
                                                    window.localStorage.removeItem("token");
                                                    window.localStorage.removeItem("created_at");
                                                }}>刪除ID</button>
                                            </div>
                                        </div>
                                        <div className={'bg-gray-300/50 absolute -top-[15px] -left-[15px] -right-[15px] -bottom-[15px] rounded ' + (newID != "" && newIdToken != "" && authToken != "" ? "hidden" : "")}>
                                            {
                                                newIDLoading ?
                                                    <div className='flex items-center justify-center h-full'>
                                                        <svg className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <button onClick={() => {
                                                        setShowCreateIDModal(true);
                                                    }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto' >
                                                        建立共用計時器
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                    <div className='mt-1 sm:col-span-2 '>
                                        <StyleSelector style={newStyle} setStyle={setNewStyle}></StyleSelector>
                                    </div>
                                    <div className='text-center sm:col-span-2'>進入倒數介面後，連點3下螢幕開啟設定介面</div>
                                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                                        <button
                                            onClick={() => {
                                                create();
                                            }}
                                            type="button"
                                            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto"
                                        >
                                            建立
                                        </button>
                                    </div>
                                </div>
                            </Transition>
                            <button
                                onClick={() => {
                                    toggleBlock();
                                }}
                                className="relative mt-8 block w-full outline-none">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span className="bg-white pr-3 text-lg font-medium text-gray-900">加入共用計時器</span>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-full border border-gray-300 bg-white px-1.5 py-1.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        {
                                            showBlock == "join" ?
                                                <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                :
                                                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        }
                                    </button>
                                </div>
                            </button>
                            <Transition
                                show={showBlock == "join"}
                                enter="transition-all duration-500 overflow-hidden"
                                enterFrom="max-h-0"
                                enterTo="max-h-[300vh]"
                                leave="transition-all duration-500 overflow-hidden"
                                leaveFrom="max-h-[300vh]"
                                leaveTo="max-h-0"
                                as='div'
                            >

                                <div className={"mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"}>
                                    <div>
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                                            倒數計時器ID
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={id}
                                                onChange={(e) => setID(e.target.value)}
                                                type="text"
                                                autoComplete="off"
                                                className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-1 sm:col-span-2 '>
                                        <StyleSelector style={style} setStyle={setStyle}></StyleSelector>
                                    </div>
                                    <div className='text-center sm:col-span-2'>進入倒數介面後，連點3下螢幕開啟設定介面</div>
                                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                                        <button
                                            onClick={() => {
                                                join();
                                            }}
                                            type="button"
                                            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto"
                                        >
                                            加入
                                        </button>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showCreateIDModal} onClose={setShowCreateIDModal} buttons={[
                {
                    color: "red",
                    title: "取消",
                    onClick: () => {
                        setShowCreateIDModal(false);
                    }
                },
                {
                    color: "green",
                    title: "確定",
                    onClick: () => {
                        setShowCreateIDModal(false);
                        getNewID();
                    }
                }
            ]}>
                <div>
                    <h2 className='text-center text-2xl'>建立新的共用ID</h2>
                    <p className='mt-4'>
                        建立的ID可以儲存7天，過期後將會自動刪除。建立後此計時器會被設定為共用計時器，其他人可以透過此ID加入此計時器。
                    </p>
                    <p className='my-2'>
                        此功能會使用到伺服器的效能，如果你願意支持這個奇怪的計時器，可以透過下方的連結來支持我。
                    </p>
                    <div className='mb-2 mt-4 text-center'>
                        <a href="https://www.buymeacoffee.com/dannyball710" target={"_blank"} className=" inline-block">
                            <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style={{ height: 51, width: 217 }} />
                        </a>
                    </div>
                </div>
            </Modal>
            <Modal show={showShare} onClose={setShowShare} buttons={[
                {
                    color: "red",
                    title: "關閉",
                    onClick: () => {
                        setShowShare(false);
                    }
                }
            ]}>
                <div className='my-4'>
                    <div className=' text-center mt-4'>
                        <div className='p-2 shadow inline-block rounded'>
                            <QRCodeSVG value={shareURL} />
                        </div>
                    </div>
                    <div className='relative  mt-4'>
                        <input
                            readOnly={true}
                            value={shareURL}
                            type="text"
                            autoComplete="off"
                            className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                        />
                        <button onClick={() => {
                            navigator.clipboard.writeText(shareURL);
                        }} className='absolute top-1/2 -translate-y-1/2 right-2 inline-flex items-center justify-center rounded-md border border-transparent bg-slate-600 p-2 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 w-auto'>
                            <ClipboardIcon className='w-5 h-5 text-white' />
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}