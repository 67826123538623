
import style1Image from "../image/style1.png";
import style2Image from "../image/style2.png";
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StyleSelector({ style, setStyle }) {
    return <RadioGroup value={style} onChange={setStyle}>
        <RadioGroup.Label className="text-lg font-medium text-gray-900">選擇樣式</RadioGroup.Label>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 select-none">
            <RadioGroup.Option
                value={"phone"}
                className={({ checked, active }) =>
                    classNames(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'ring-2 ring-indigo-500' : '',
                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                    )
                }
            >
                {({ checked, active }) => (
                    <>
                        <span className="flex flex-1">
                            <span className="flex flex-col">
                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                    手機
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                    as="span"
                                    className="mt-1 flex items-center text-sm text-gray-500"
                                >
                                    <img src={style1Image} className="w-48 rounded object-contain pointer-events-none" alt="" />
                                </RadioGroup.Description>
                            </span>
                        </span>
                        {checked ? <CheckCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" /> : null}
                        <span
                            className={classNames(
                                active ? 'border' : 'border-2',
                                checked ? 'border-indigo-500' : 'border-transparent',
                                'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                        />
                    </>
                )}
            </RadioGroup.Option>
            {/* <RadioGroup.Option
                value={"screen"}
                className={({ checked, active }) =>
                    classNames(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'ring-2 ring-indigo-500' : '',
                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                    )
                }
            >
                {({ checked, active }) => (
                    <>
                        <span className="flex flex-1">
                            <span className="flex flex-col">
                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                    螢幕
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                    as="span"
                                    className="mt-1 flex items-center text-sm text-gray-500"
                                >
                                    <img src={style2Image} className=" w-48 rounded object-contain pointer-events-none" alt="" />
                                </RadioGroup.Description>
                            </span>
                        </span>
                        {checked ? <CheckCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" /> : null}
                        <span
                            className={classNames(
                                active ? 'border' : 'border-2',
                                checked ? 'border-indigo-500' : 'border-transparent',
                                'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                        />
                    </>
                )}
            </RadioGroup.Option> */}
        </div>
    </RadioGroup>
}