import { useState } from "react";
import Modal from "../utils/Modal";

export default function ClockSettingModal({ clock, onClose, show }) {
    let [tick, setTick] = useState(0);

    function update() {
        setTick((a) => { return a + 1 });
    }
    return <Modal show={show} onClose={onClose} buttons={[]}>
        <div className="mt-6">
            <div className="py-1 grid grid-cols-1 md:grid-cols-3 gap-4">
                {
                    clock.type == "owner" ?
                        <>
                            {
                                clock.status == 1 ?
                                    <>
                                        <button onClick={() => {
                                            clock.pause();
                                            update();
                                        }} className="cursor-pointer inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                                            </svg>
                                            暫停
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button onClick={() => {
                                            clock.start();
                                            update();
                                        }} className="cursor-pointer inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                            </svg>
                                            開始
                                        </button>
                                    </>
                            }
                            <button onClick={() => {
                                clock.reset();
                                update();
                            }} className="cursor-pointer inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                                重置
                            </button>
                        </>
                        :
                        null
                }
                <button onClick={() => {
                    window.location.assign("/");
                }} className="cursor-pointer inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                    回首頁
                </button>
            </div>
            {
                clock.type == "guest" ?
                    <div className="text-center mt-6">無法設定其他人的倒數計時器</div>
                    :
                    null
            }
        </div>
    </Modal>
}