var timeOffset = 0;
var synced = false;
export async function syncTime() {
    let startTime = Date.now();
    let response = await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC');
    let data = await response.json();
    let endTime = Date.now();
    let time = data.unixtime * 1000 + (endTime - startTime) / 2;
    timeOffset = time - Date.now();
    console.log(timeOffset);
    synced = true;
}

export function getTime() {
    return Date.now() + timeOffset;
}

export function isSynced() {
    return synced;
}

export function getTimeOffset() {
    return timeOffset;
}